<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="所属仓库" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div>{{ item.warehouse_name }}</div>
      </a-form-item>
      <a-form-item label="出库物品" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select placeholder="请选择" @change="changeProduct" showSearch @search="handleSearch" :filterOption="filterOption"
           v-decorator="['product_id', { rules: [{ required: true, message: '请选择出库物品!' }] }]">
          <a-select-option v-for="d of products" :key="d.product_id" :value="d.product_id">{{ d.product_name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="物品数量" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input @blur="changeCount" v-decorator="['product_count', { rules: [{ required: true, message: '请输入物品数量!' }] }]"/>
      </a-form-item>
      <a-form-item label="物品总价" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['product_total_price', { rules: [{ required: true, message: '请输入物品总价!' }] }]"/>
      </a-form-item>
      <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea :rows="4" v-decorator="['opt_name', { rules: [] }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '物品出库',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      products: [],
      product: {},
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getWarehouseProduct()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getWarehouseProduct(val) {
      let res = await this.$store.dispatch('searchWarehouseProductAction',{params:{q:val,warehouse_id:this.item.warehouse_id}})
      this.products = res.data
      this.$forceUpdate()
    },
    // async getDetail(warehouse_id) {
    //   let res = await this.$store.dispatch('stockWarehouseDetailAction', {data: {warehouse_id}})
    //   return res.data
    // },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.warehouse_id = item.warehouse_id
          }
          let res = await this.$store.dispatch('stockProductMinusAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideIncomeModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideMinusModal(0)
    },
    changeCount() {
      let product_count = this.form.getFieldValue('product_count') || 1
      this.form.setFieldsValue({
        product_count,
        product_total_price: (this.product.product_income_price * product_count).toFixed(2)
      })
    },
    changeProduct(v) {
      for (let item of this.products) {
        if (item.product_id === v) {
          this.product = item
          break
        }
      }
      this.changeCount()
    },
    handleSearch(val){
      this.getWarehouseProduct(val)
    },
  }
}
</script>
