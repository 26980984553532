<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>库存管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">仓库管理<a-badge :count="0" /></span>
          <ckgl v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">库存列表<a-badge :count="0" /></span>
          <kclb v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">物品管理<a-badge :count="0" /></span>
          <wpgl v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">进出记录<a-badge :count="0" /></span>
          <jcjl v-if="activeKey==='4'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="5">
          <span slot="tab">库存统计<a-badge :count="0" /></span>
          <kctj v-if="activeKey==='5'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import ckgl from './ckgl/index'
import kclb from './kclb/index'
import wpgl from './wpgl/index'
import jcjl from './jcjl/index'
import kctj from './kctj/index'

export default {
  components: {
    ckgl,
    kclb,
    wpgl,
    jcjl,
    kctj,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

