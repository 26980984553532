<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增物品</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>物品名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.product_name" placeholder="物品名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>物品类型</span>
              </template>
              <a-select allowClear v-model="searchData.product_type" placeholder="物品类型" style="width: 140px">
                <a-select-option v-for="(d, index) of productType" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="product_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>

          <template slot="product_count" slot-scope="text, record">
              {{record.product_count}} {{record.product_units}}
          </template>

          <template slot="action" slot-scope="text,record">
            <!-- <a href="javascript:;" @click="deleteItem(record)">删除</a>
            <a-divider type="vertical" /> -->
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '物品名称', dataIndex: 'product_name', key: 'name' },
  { title: '物品类型', dataIndex: 'product_type', key: '1' },
  { title: '物品进价', dataIndex: 'product_income_price', key: '2' ,align:'right'},
  { title: '物品售价', dataIndex: 'product_sale_price', key: '3' ,align:'right'},
  { title: '物品库存', dataIndex: 'product_count', key: '5', scopedSlots: { customRender: 'product_count' } ,align:'right'},
  { title: '库存预警数', dataIndex: 'product_alarm', key: '4',align:'right' },
  { title: '操作', key: 'operation', width: 46, scopedSlots: { customRender: 'action' }},
]
								
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      productType: C_ITEMS.productType,

      searchData: {},

      visible: false,
      modalData: {},
      editVisible: false,
      authType:['orginization','stock'],
    }
  },
  mixins: [ authority, tableMixins ],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('stockProductAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除"${item.product_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('stockWarehouseDeleteAction', { data: { warehouse_id: item.warehouse_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
