<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <!-- <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button> -->
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>所在仓库</span>
              </template>
              <a-select allowClear v-model="searchData.warehouse_id" placeholder="所在仓库" style="width: 140px">
                <a-select-option v-for="(d, index) of warehouses" :key="index" :value="d['warehouse_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>物品名称</span>
              </template>
              <a-select allowClear v-model="searchData.product_id" placeholder="物品名称" style="width: 140px">
                <a-select-option v-for="(d, index) of products" :key="index" :value="d['product_id']">{{ d['product_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>物品操作</span>
              </template>
              <a-select allowClear v-model="searchData.product_opt" placeholder="物品操作" style="width: 140px">
                <a-select-option v-for="(d, index) of productOpt" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '物品名称', dataIndex: 'product_id', key: '1' },
  { title: '仓库名称', dataIndex: 'warehouse_id', key: 'name' },
  { title: '物品类型', dataIndex: 'product_type', key: '2' },
  { title: '物品操作', dataIndex: 'product_opt', key: '3' ,align:'center'},
  { title: '备注', dataIndex: 'opt_name', key: '8' ,ellipsis: true},
  { title: '经办人', dataIndex: 'created_by', key: 'created_by' },
  { title: '操作时间', dataIndex: 'created_at', key: '10' ,align:'right'},
]

import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      productOpt: C_ITEMS.productOpt,
      warehouses: [],
      products: [],

      searchData: {},
      authType:['orginization','stock'],
      visible: false
    }
  },
  mixins: [ authority, tableMixins ],
  created() {
    this.getWarehouses()
    this.getProducts()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getWarehouses() {
      let res = await this.$store.dispatch('searchWarehouseAction', {})
      this.warehouses = res.data
    },
    async getProducts() {
      let res = await this.$store.dispatch('searchWarehouseProductAction', {})
      this.products = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('stockProductRecordsAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
