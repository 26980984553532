<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增仓库</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>仓库名称</span>
              </template>
              <a-input v-model.trim="searchData.warehouse_name" placeholder="仓库名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="warehouse_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="action" slot-scope="text,record">
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
            <a-divider v-if="canUpdate" type="vertical" />
            <a href="javascript:;" @click="showMinusModal(record)">出库</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="showIncomeModal(record)">入库</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <incomeModal v-if="incomeVisible" :item="modalData"/>
    <minusModal v-if="minusVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '仓库名称', dataIndex: 'warehouse_name', key: 'name' },
  { title: '所在校区', dataIndex: 'studio_id', key: 'studio_id' },
  { title: '物品总数', dataIndex: 'warehouse_product_count', key: '1' ,align:'right'},
  { title: '成本总金额', dataIndex: 'warehouse_price_count', key: '2' ,align:'right'},
  { title: '总出库金额', dataIndex: 'warehouse_sale_price_count', key: '3',align:'right' },
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' },align:'right'},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import incomeModal from './incomeModal'
import minusModal from './minusModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal,
    incomeModal,
    minusModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},

      visible: false,
      modalData: {},
      editVisible: false,
      incomeVisible: false,
      minusVisible: false,
      authType:['orginization','stock'],
    }
  },
  mixins: [ authority, tableMixins ],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('stockWarehouseAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showIncomeModal(item) {
      this.modalData = item
      this.incomeVisible = true
    },
    hideIncomeModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.incomeVisible = false
    },
    showMinusModal(item) {
      this.modalData = item
      this.minusVisible = true
    },
    hideMinusModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.minusVisible = false
    }
  }
}
</script>
